<template lang="">
<div class="settingContent">
    <div class="handleBox flex flex-jc-sb flex-ai-c">
        <h3 style="">{{$route.name}}</h3>
        <el-button type="primary" size="small" @click="handleEdit()">新建</el-button>
    </div>

    <div class="contentBox" style="width:100%">
        <el-tabs style="height:99%" v-model="tabName" @tab-click="handleClick">
            <el-tab-pane :label="i" :name="i" v-for="i in tabs">
                <el-table ref="multipleTable" :data="list" highlight-current-row height="calc( 100% - 40px )">
                    <el-table-column label="序号" width="100" align="center" type="index"> </el-table-column>
                    <el-table-column prop="name" label="名称" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button @click="handleEdit(scope.row)" type="text">编辑</el-button>
                            <el-button @click="handleDel(scope.row)" type="text">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>


        <!-- <el-dialog title="设置" :visible.sync="visible" :modal-append-to-body="false" :close-on-click-modal="false" width="500px">
            <el-form ref="formAuth" :model="formData" label-position="top" size="small" class="alertForm">
                <el-form-item label="名称" prop="name" style="width:100%;margin-right:0">
                    <el-input v-model="formData.name" autocomplete="off" placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false;">取 消</el-button>
                <el-button size="small" type="primary" @click="submit()">确 定</el-button>
            </div>
        </el-dialog> -->
    </div>
</div>
</template>
<script>
export default {
    data() {
        return {
            list: [],
            tabs: ['部门信息', '岗位信息'],
			tabName:'部门信息',
			// visible:false,
			// formData:{}
        }
    },
    mounted() {
        this.getList(this.tabName)
    },
    methods: {
        handleClick(tab) {
            this.getList(tab.label)
        },
        getList(i) {
            let url = i == '部门信息' ? '/admin.department/index' : '/admin.position/index'
            this.http.post(url).then((res) => {
                this.list = res
            })
        },
        handleEdit(row) {
			let name=row?row.name:'新建';
            this.$prompt('请输入名称', `${name}`, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /\S/,
                inputErrorMessage: '请输入名称'
            }).then(({
                value
            }) => {
				let url = this.tabName == '部门信息' ? '/admin.department/save' : '/admin.position/save'
				this.http.post(url, {
					id:row?row.id:'',
					name:value
				}).then(() => {
					this.$message({
						message: '提交成功!',
						type: 'success',
					})
					this.getList(this.tabName)
				})
            }).catch(()=>{});
        },
		handleDel(row){
			this.$confirm('确定删除选中行吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
				let url = this.tabName == '部门信息' ? '/admin.department/delete' : '/admin.position/delete'
                this.http.post(url, {
                    id: row.id
                }).then(re => {
                    this.getList(this.tabName);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
            })
		}
    },
}
</script>

<style lang="less">
.el-tabs__content {
    height: calc(100% - 70px);
    > div {
        height: 100%;
    }
}
// .settingContent {
//     // width: 100%;
//     height: 100%;
//     margin-left: 50px;
//     .handleBox {
//         margin-bottom: 20px;
//     }
//     .contentBox {
//         height: calc(100% - 60px);
//         width: 700px;
//         padding: 0 50px;
//         overflow-y: auto;
//         background: #ffffff;
//         // padding: 15px 0;
//         box-sizing: border-box;
//     }
// }
</style>
